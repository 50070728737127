/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Inject, Output, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { NgHttpCachingService } from 'ng-http-caching';
import { Subject, takeUntil } from 'rxjs';

import { FeatureRequestApiService, FeatureRequestDto } from '@shure/cloud/shared/feature-request/data-access';
import { FeatureRequestMainComponent } from '@shure/cloud/shared/feature-request/feature-request';
import { OktaInterfaceService } from '@shure/cloud/shared/okta/data-access';
import { BreakpointService } from '@shure/cloud/shared/services/media-breakpoints';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ApiUsersService } from '@shure/cloud/shared/users/data-access';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { IBreadcrumbClickEvent } from '../breadcrumbs';
import { FormDataDetails } from '../dialogs/dialog-box-ignite/dialog-box.model';
import { ShIgniteDialogBoxService } from '../dialogs/dialog-box-ignite/dialog-box.service';
import { CloseTextOption, SnackbarService } from '../html-snackbar';
import { getEmailColorIndex } from '../utils/profile.util';

import { HeaderToolbarService } from './header-toolbar.service';
export interface UserInfo {
	sub: string;
	name: string;
	locale: string;
	email: string;
	preferred_username: string;
	given_name: string;
	family_name: string;
	zoneinfo: string;
	updated_at: number;
	email_verified: boolean;
	user_oidcdisplayname: string;
	Custom_Country: string;
}

@Component({
	selector: 'sh-header-toolbar',
	templateUrl: './header-toolbar.component.html',
	styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit, OnDestroy {
	@Input() public showSidenavToggleButton = false;
	@Input() public showFeatureButton = false;
	@Input() public showBreadCrumbs = true;
	@Input() public secondaryNavHeader = '';
	@Output() public toggleSidenav: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() public signOut: EventEmitter<boolean> = new EventEmitter<boolean>();
	private destroy$: Subject<void> = new Subject<void>();
	public readonly breadCrumbs = toSignal(this.headerToolbarService.getBreadCrumb$(), {
		initialValue: []
	});
	public isSmallDevice = this.breakpointService.isLteSmall;
	private userProfileSignal = toSignal(this.oktaInterfaceService.getUserProfile$());
	public usersInitials = '';
	public getImageUrl$ = this.headerToolbarService.getImageUrl$;
	public isImageRemoved$ = this.headerToolbarService.isImageRemoved$;
	public colorIndex!: number;
	public profileChars = this.headerToolbarService.profileChars$;
	public imageOpacity = 0;

	private featureformData: FormDataDetails[] = [
		{
			label: this.translocoService.translate('cloud.shared.header.idea-summary'),
			name: 'title',
			validators: [Validators.pattern('^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$')],
			type: 'text'
		},
		{
			label: this.translocoService.translate('cloud.shared.header.more-details'),
			name: 'description',
			validators: [Validators.required, Validators.pattern('^[a-zA-Z0-9_]+( [a-zA-Z0-9_.,]+)*$')],
			type: 'textarea'
		}
	];

	constructor(
		private oktaInterfaceService: OktaInterfaceService,
		private router: Router,
		private headerToolbarService: HeaderToolbarService,
		private ngHttpCachingService: NgHttpCachingService,
		public oktaIntfService: OktaInterfaceService,
		private shDialogService: ShIgniteDialogBoxService,
		private translocoService: TranslocoService,
		private breakpointService: BreakpointService,
		@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
		public featureRequestservice: FeatureRequestApiService,
		public snackBarService: SnackbarService,
		public dialog: MatDialog,
		private apiUsersService: ApiUsersService,
		private logger: ILogger
	) {
		effect(() => {
			const userProfile = this.userProfileSignal();
			if (userProfile !== undefined) {
				this.usersInitials =
					userProfile.firstName?.charAt(0)?.toUpperCase() + userProfile.lastName?.charAt(0)?.toUpperCase();
			}
		});
	}

	public async logout(): Promise<void> {
		//Clear all the cache
		this.ngHttpCachingService.clearCache();
		this.signOut.emit(true);
		await this.oktaInterfaceService.signOut();
	}

	public onSidenavToggle(): void {
		this.toggleSidenav.emit(true);
	}

	public async ngOnInit(): Promise<void> {
		const userInfo = await this.oktaAuth.getUser();
		this.colorIndex = getEmailColorIndex(userInfo?.email);
		this.headerToolbarService.profileChars$.next(
			(userInfo.given_name || '').charAt(0) + (userInfo.family_name || '').charAt(0)
		);
		this.generateViewUrl();
	}
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	/**
	 * Handle Breadcrumb click event and redirect to page
	 * @param breadCrumb - Breadcrumb which is clicked
	 */
	public async breadCrumbRedirect(breadCrumb: IBreadcrumbClickEvent): Promise<void> {
		await this.router.navigate([`/${breadCrumb.id}`]);
	}

	public openFeatureRequestDialog(): void {
		const dialogRef = this.dialog.open(FeatureRequestMainComponent, {
			width: '447px'
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this.destroy$))
			.subscribe((result) => {
				if (result?.data) {
					this.submitFeatureRequest(<FeatureRequestDto>result?.data);
				}
			});
	}

	/**
	 *	This method is called to generate the view Image Url
	 */
	public generateViewUrl(): void {
		const projectionString = 'viewProfileImageUrl';
		this.apiUsersService
			.getAccountInfo$Response({ projections: projectionString })
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (accountInfoResponse) => {
					const accountInfoDetailsBody = JSON.parse(<string>(<unknown>accountInfoResponse.body));
					if (accountInfoDetailsBody?.body) {
						const accountInfoDetails = accountInfoDetailsBody?.body;
						const profileImageUrl = accountInfoDetails.viewProfileImageUrl?.original;
						if (profileImageUrl) {
							this.handleProfileImage(profileImageUrl);
						}
					}
				},
				error: (error) => {
					this.logger.error('generateViewUrl', 'view Image Url', { error });
					const errorResponse = JSON.parse(error.error);
					this.snackBarService.open(
						this.translocoService.translate(
							errorResponse.messageKey
								? 'cloud-licensing-portal.messages.' + errorResponse.messageKey
								: errorResponse.message
						),
						CloseTextOption.Ok
					);
				}
			});
	}

	/**
	 * This method is called to handle Profile Image
	 * @param profileImageUrl - View Image Url
	 */
	public handleProfileImage(profileImageUrl: string): void {
		const img = new Image();
		img.onload = () => {
			this.headerToolbarService.getImageUrl$.next(profileImageUrl);
			this.headerToolbarService.isImageRemoved$.next(false);
		};
		img.onerror = () => {
			this.headerToolbarService.isImageRemoved$.next(true);
		};
		img.src = profileImageUrl;
	}

	/**
	 * Handle click event to redirect to Shure ID Faq page
	 */
	public navigateToShureIdFaq(): void {
		window.open('https://p.shure.com/myshureid-faqs');
	}

	/**
	 * This method is called when the profile image has been successfully loaded.
	 * It can be used to perform any actions needed after the image is fully loaded,
	 * such as adjusting the image's opacity, applying additional styles, or triggering other events.
	 */
	public onImageLoad(): void {
		this.imageOpacity = 1;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
	public onImageLoadError(event: any) {
		this.headerToolbarService.getImageUrl$.next('');
	}

	/**
	 *	This method is called to submit the feature request using the response
	 * 	@param featureRequestData - object of type FeatureRequestDto
	 */
	private submitFeatureRequest(featureRequestData: FeatureRequestDto): void {
		this.featureRequestservice
			.submitFeatureRequest$Response({ body: featureRequestData })
			?.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (featureRequestResponse) => {
					const featureRequestResponseBody = JSON.parse(<string>(<unknown>featureRequestResponse?.body));
					this.snackBarService.open(
						this.translocoService.translate(
							'cloud.shared.header.' + featureRequestResponseBody?.body?.messageKey
						),
						CloseTextOption.Ok
					);
				},
				error: (error) => {
					const errorResponse = error.error;
					this.snackBarService.open(
						this.translocoService.translate(
							errorResponse.i18nKey
								? 'cloud.shared.error-labels.' + errorResponse.i18nKey
								: errorResponse.message
						),
						CloseTextOption.Ok
					);
				}
			});
	}
}
