import { DeviceBatteryLevel, DeviceMicState, DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

import { DeviceState } from './device-state.enum';
import { FirmwarePackage } from './firmware-package';
import { FirmwareUpdateProgress } from './firmware-update-progress';
import { isUpdateInProgress } from './inventory-device';
import { LicenseInfo } from './license-info';
import { MissingFeatureType } from './missing-feature-type';
import { NetworkInterface } from './network-interface';

interface AudioMute {
	muted: boolean;
}

interface Identify {
	identifying: boolean;
}

interface MicStatus {
	status: DeviceMicState;
}

export interface PropertyPanelDevice {
	id: string;
	name: string;
	model: DeviceModel;
	status: DeviceState;
	isVirtual: boolean;

	constraints?: {
		name?: {
			regex: string;
		};
	};

	features: {
		audioChannelCount?: {
			count: number;
		} & MissingFeatureType;
		audioNetwork?: NetworkInterface & MissingFeatureType;
		availablePackages?: {
			primaryPackages: FirmwarePackage[];
		} & MissingFeatureType;
		batteryLevel?: DeviceBatteryLevel & MissingFeatureType;
		controlNetwork?: NetworkInterface & MissingFeatureType;
		danteName?: {
			danteName: string;
		} & MissingFeatureType;
		firmware?: {
			version: string;
			valid: boolean;
		} & MissingFeatureType;
		identify?: Identify & MissingFeatureType;
		license?: {
			info?: LicenseInfo;
		} & MissingFeatureType;
		licenseV2?: {
			info?: LicenseInfo[];
		} & MissingFeatureType;
		micStatus?: MicStatus & MissingFeatureType;
		mute?: AudioMute & MissingFeatureType;
		rfChannels?: {
			channels: RfChannel[];
		} & MissingFeatureType;
		serialNumber?: {
			serialNumber: string;
		} & MissingFeatureType;
		uptime?: {
			uptime: string;
		} & MissingFeatureType;
		updateProgress?: FirmwareUpdateProgress & MissingFeatureType;
		tags?: {
			tags: string[];
		} & MissingFeatureType;
	};
}

export interface RfChannel {
	linkedDevice?: RfChannelLinkedTransmitter;
}

export interface RfChannelLinkedTransmitter {
	id: string;
	name: string;
	model: DeviceModel;
	status: DeviceState;
	features: Pick<PropertyPanelDevice['features'], 'batteryLevel' | 'identify' | 'micStatus' | 'mute'>;
}

/**
 * Returns true if the device can be interacted with via the property panel, i.e. if it:
 * - Is online
 * - Is not udpating
 */
export function canInteract(device: PropertyPanelDevice): boolean {
	return device.status === DeviceState.Online && !isUpdateInProgress(device.features.updateProgress?.stage);
}
